import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const ContactBox = () => {
  return (
    <section className="contact-box">
      <Container>
        <Row className="contact-box-row">
          <Col sm={12} md={6}>
            <div className="contact-box-header">
              <h3>
                Want to talk to one of our advisors?
                <br />
                Leave your details or give us a call.
              </h3>
              <div className="mt-4">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    lineHeight: 1,
                  }}
                >
                  <span
                    style={{
                      fontSize: '.8em',
                      letterSpacing: '3px',
                      opacity: 0.6,
                      textTransform: 'uppercase',
                    }}
                  >
                    Phone
                  </span>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '2.75em',
                      fontWeight: 'bold',
                      letterSpacing: '-.5px',
                      margin: 0,
                    }}
                  >
                    07494 160 670
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    lineHeight: 1,
                    marginTop: '1rem',
                  }}
                >
                  <span
                    style={{
                      fontSize: '.8em',
                      letterSpacing: '3px',
                      opacity: 0.6,
                      textTransform: 'uppercase',
                    }}
                  >
                    Address
                  </span>
                  <address style={{ lineHeight: 1.4 }}>
                    Riseley House
                    <br />4 New Road
                    <br />
                    Rochester
                    <br />
                    Kent
                    <br />
                    ME1 1BD
                  </address>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <Form
              goform=""
              action="https://goform.app/s/LS8kS2HR4FDwEo4KCaudZwdOzMTqWHKX"
              method="post"
              noValidate
            >
              <FormGroup>
                <Label for="name">
                  Your Full Name <span>*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Full Name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  Your Email Address <span>*</span>
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email Address"
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">
                  Your Phone Number <span>*</span>
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Your Phone Number"
                />
              </FormGroup>

              <Button color="danger" type="submit">
                Submit
              </Button>

              <p className="form-note">
                <span>* </span>
                By submitting details on this form you’re agreeing to Summit
                Wealth Ltd and it's business partners contacting you.
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactBox;
